@font-face {
	font-family: "NotoSansLight";
	src: local("NotoSansLight"), url("./fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
	font-weight: 300;
}

@font-face {
	font-family: "NotoSans";
	src: local("NotoSans"), url("./fonts/NotoSans/NotoSans-Medium.ttf") format("truetype");
	font-weight: 500;
}
@font-face {
	font-family: "NotoSansBold";
	src: local("NotoSansBold"), url("./fonts/NotoSans/NotoSans-Bold.ttf") format("truetype");
	font-weight: 700;
}

body {
	margin: 0;
	font-family: "NotoSans", -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 500;
	font-size: 0.9rem;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
