@import "../../App/App.scss";

.big-number {
	font-size: 1.8rem;
	text-align: center;
	font-family: $title-font;
	color: $blue;
	margin-top: 0.75rem;
	background-color: $light-color;
	padding: 0.5rem 0;
	border-radius: 8px;
}

.template-button {
	padding: 0.25rem 0.75rem;
	margin: 0;
	color: $green;
	border-radius: 10px;
	@include fluid-type(0.95rem, 1rem);
	background-color: $white;
	border: solid 1px $green;
	cursor: pointer;
	font-family: $title-font;
}

.template-button:hover {
	color: $white !important;
	background-color: $green;
	border: solid 1px $green;
	text-decoration: none;
}

.excel-big-badge {
	padding: 0.25rem 1.25rem;
	margin-top: 0.5rem;
	color: $white;
	border-radius: 10px;
	@include fluid-type(0.95rem, 1rem);
	background-color: $green;
	font-family: $title-font;
	border: solid 1px $green;
	cursor: pointer;
}

.excel-big-badge:hover {
	color: $primary-color !important;
	background-color: $white !important;
	text-decoration: none;
}

.menu-container {
	position: relative;
	display: inline;
}

.menu {
	border: solid 1px $secondary-color;
	position: absolute;
	top: 0px;
	right: 25px;
	width: 275px;
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
	z-index: 9;
}

.menu.active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

.menu li {
	border-bottom: 1px solid $light-color;
	padding: 0.5rem 0 0.5rem 0.5rem;
	margin: 0;
	color: $white;
	font-family: $title-font;
	list-style: none;
	background-color: $secondary-color;
}

.menu li:hover {
	background-color: $light-color;
	color: $primary-color;
}
