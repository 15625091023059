@import "../../../App/App.scss";

.ProseMirror {
	border: solid 1px lighten($color: $grey, $amount: 25);
	border-radius: 2px;
	height: auto;
	min-height: 10rem;
	padding: 1rem;
	margin: 0;

	> * + * {
		margin-top: 0.75em;
	}

	ul,
	ol {
		padding: 0 1rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #0d0d0d;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	mark {
		background-color: #faf594;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}

	hr {
		border: none;
		border-top: 2px solid rgba(#0d0d0d, 0.1);
		margin: 2rem 0;
	}
}

#rich-editor {
	button {
		border: solid 1px lighten($color: $grey, $amount: 25);
		border-radius: 2px;
		margin: 0 1px 1px 0;
		background-color: #fff;
		color: $primary-color;
		padding: 0.5rem;
		width: 3rem;
		height: 3rem;
	}
	button:hover {
		background-color: $secondary-color;
		color: #fff;
	}
	button:active {
		background-color: $primary-color;
		color: #fff;
	}
	.editor-icon {
		height: 1.5rem;
		width: auto;
	}
	.bubble-menu {
		button {
			border: none;
			border-radius: 0;
			margin: 0;
			padding: 0;
			background-color: $secondary-color;
			color: $white;
			width: 2rem;
			height: 2rem;
		}
		button:hover {
			background-color: $white;
			color: $secondary-color;
		}
		.editor-icon {
			height: 1.1rem;
			width: auto;
			margin: 0;
			padding: 0;
		}
	}
}
