@import "../../App/App.scss";

.preview-post {
	// width: 150px;
	// height: auto;
	position: relative;
	display: inline-block;
	margin: 0.5rem;
}

.preview-pict-slideshow {
	width: auto;
	height: 90px;
	max-width: 120px;
	margin: 0.5rem 0;
	object-fit: cover;
}

.preview-pict {
	width: 120px;
	height: 120px;
	object-fit: cover;
}

.video-btn {
	background-color: $contrast-color;
	color: $white;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 50%;
	border: solid 1px $light-color;
	padding: 0;
}

.video-btn:hover {
	background-color: $blue;
}

.pict-btn {
	background-color: $contrast-color;
	color: $white;
	font-size: 0.6rem;
	font-family: $text-font;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	position: absolute;
	top: -8px;
	right: 8px;
	border: solid 1px $light-color;
}

.pict-btn:hover {
	background-color: $blue;
}

.post-suppr-btn {
	background-color: $contrast-color;
	color: $white;
	width: 1.4rem;
	height: 1.4rem;
	border-radius: 50%;
	border: solid 1px $light-color;
}

.social-thumb {
	width: 95%;
	max-width: 800px;
	height: auto;
	margin: 0 auto;
}

.social-img-block {
	margin: 1rem 0 1rem 0;
	display: inline-flex;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	width: 100%;
}

.textarea-style {
	border: 1px solid lighten($color: $grey, $amount: 30);
	height: 100%;
	min-height: 11rem;
	cursor: text;
	border-radius: 5px;
	padding: 0.5rem;
	margin: 0.5rem 0;
	font-family: $text-font;
	@include fluid-type(0.85rem, 0.9rem);
	display: block;
	overflow: auto;
}

.single-post-pict {
	position: relative;
	margin: 0 0 1rem 0;

	.single-post-pict-download {
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 3rem;

		.single-post-pict-download-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			color: white;
		}
		.single-post-pict-download-icon {
			height: 1.5rem;
			width: auto;
		}
	}
}

.header-details-button {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
}

.pict-btn {
	background-color: $contrast-color;
	color: $white;
	font-size: 0.6rem;
	font-family: $text-font;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 50%;
	position: absolute;
	top: -8px;
	right: 8px;
	border: solid 1px $light-color;
}

.pict-btn:hover {
	background-color: $blue;
}

.post-to-moderate {
	border: solid 2px lighten($color: $contrast-color, $amount: 20);
	margin: 0.75rem 0.75rem 0.75rem 0;
	padding: 0;
	border-radius: 7px;

	h3 {
		letter-spacing: 0.5px;
		margin: 0;
		padding: 0.5rem 0;
	}

	.header {
		background-color: lighten($color: $contrast-color, $amount: 32);
	}

	.single-kpi {
		border-top: solid 1px lighten($color: $contrast-color, $amount: 20);
		margin: 0;
		padding: 1rem;

		p {
			padding: 0;
			margin: 0;
			vertical-align: middle;
		}

		.kpi-icon {
			padding: 0;
			margin: 0 0.5rem 0 0;
			height: 1.1rem;
			width: auto;
			vertical-align: middle;
		}
	}
}

.big-kpi-icon {
	padding: 0;
	margin: 0 0.6rem 0 0;
	height: 1.5rem;
	width: auto;
	vertical-align: middle;

	&.blue {
		color: $blue;
	}
	&.red {
		color: $contrast-color;
	}
	&.regular {
		color: $primary-color;
	}
}

.big-kpi-icon:hover {
	&.red {
		color: $secondary-color;
	}
	&.regular {
		color: $secondary-color;
	}
}

.news-moderation-link {
	cursor: pointer;
}

.news-moderation-link:hover {
	color: $blue;
}

// LOOK LIKE FAMILY

.news-container {
	margin: 0 auto;
	width: 100%;
	max-width: 50rem;
}

.social-card {
	border: solid 1px lighten($color: $grey, $amount: 30);
	margin: 2rem 0;
	padding: 0;
	border-radius: 7px;
	background-color: $white;
}

.social-info {
	margin: 0 0 0.5rem 1rem;
	@include fluid-type(0.8rem, 0.85rem);
	letter-spacing: 0.5px;
}

.focus-nav-icon {
	width: auto;
	height: 1.1rem;
	margin: 0.2rem;
	color: $secondary-color;
	cursor: pointer;
}

.focus-nav-icon:hover {
	color: $primary-color;
}

.pict {
	display: block;
	width: 100%;
	height: 20rem;
	object-fit: cover;
}

.pict-container {
	display: inline;
}

.inline-picts {
	width: 33.3%;
	height: 20rem;
	object-fit: cover;
}

.post-content {
	h2 {
		font-size: 1.15rem;
		letter-spacing: 0.3px;
		border: none;
		margin: 0;
	}
}

.social-text {
	font-family: $text-font;
	color: $primary-color;
	padding: 1rem;
	@include fluid-type(0.85rem, 0.9rem);
	line-height: 1.25rem;
	margin: 0;
	height: 100%;
	max-height: 10rem;
	overflow: auto;
}

.social-text-link {
	font-family: $text-font;
	color: $primary-color;
	padding: 1.25rem 0.75rem;
	@include fluid-type(0.8rem, 0.85rem);
	line-height: 1.35rem;
}

.social-text-link.fake-link:hover {
	color: $contrast-color;
}

.social-separator {
	border-top: solid 1px lighten($color: $grey, $amount: 40);
	padding: 0;
	margin: 0;
}

.social-icon {
	vertical-align: middle;
	width: 1.25rem;
	height: auto;
}

.social-like {
	margin: 0.7rem 0;
}

.column {
	float: left;
	width: 50%;
	padding: 0.5rem;
}

.row:after {
	content: "";
	display: table;
	clear: both;
}

.big-column {
	float: left;
	width: 70%;
	padding: 0.5rem;
}

.small-column {
	display: inline-block;
	width: 30%;
	padding: 0.5rem;
	position: relative;
	height: 5.5rem;
}

.center-column {
	vertical-align: middle;
	height: 2rem;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0;
}

.news {
	margin-top: 1rem;
	color: $contrast-color;
	@include fluid-type(0.95rem, 1rem);
	font-family: $title-font;
	cursor: pointer;
}

.blog-title {
	@include fluid-type(1rem, 1.05rem);
	font-family: $text-font;
}

.news:hover {
	background-color: $secondary-color;
}

.news-list {
	margin-left: 0.5rem;
	list-style: none;

	li {
		padding: 0.5rem 0;
		border-bottom: solid 1px $secondary-color;
	}
}

.pictures-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.full-pict-post {
	width: 100%;
	max-width: 800px;
	height: auto;
	margin: 0;
	display: block;
}

.fixed-card {
	position: fixed;
	width: 28%;
}

.calendar-icon {
	vertical-align: middle;
	width: 20px;
	height: auto;
	padding-right: 0.25rem;
}

.like-card {
	border-top: solid 1px $secondary-color;
	border-bottom: solid 1px $secondary-color;

	margin: 0.5rem 0;
	padding: 0.5rem;
	float: left;
	width: 50%;
}

.video-container {
	display: inline-block;
	width: 100%;
	max-width: 38rem;
	margin: 0 auto;
}

.slideshow {
	width: auto;
	height: 100%;
	max-height: 20rem;
	margin: 0 auto;
}

.slideshow-background {
	background-color: $light-color;
	margin: 1rem 0;
	padding: 0;
	max-height: 10rem;
	overflow: auto;
	position: relative;
}

.like-button {
	color: $contrast-color;
	font-family: $title-font;
	@include fluid-type(0.8rem, 0.85rem);
	cursor: pointer;
}

.like-button:hover {
	color: $secondary-color;
}

.comment-button {
	color: $secondary-color;
	font-family: $title-font;
	@include fluid-type(0.8rem, 0.85rem);
	cursor: pointer;
}

.comment-button:hover {
	color: $primary-color;
}

.family-outline-badge {
	padding: 0.25rem 0.5rem 0.25rem 0.5rem;
	margin: 0.5rem 0rem;
	border-radius: 7px;
	@include fluid-type(0.8rem, 0.85rem);
	font-family: $title-font;
	border: none;
	background-color: $white;

	.meta {
		@include fluid-type(0.78rem, 0.8rem);
		line-height: 1.25rem;
	}

	&.blue {
		border: solid 1px $secondary-color;
		color: $secondary-color;
	}
	&.red {
		border: solid 1px $contrast-color;
		color: $contrast-color;
	}
	&.green {
		border: solid 1px $green;
		color: $green;
	}
	&.yellow {
		border: solid 1px $yellow;
		color: $yellow;
	}
	&.purple {
		border: solid 1px $violet;
		color: $violet;
	}
	&.marine {
		border: solid 1px $marine;
		color: $marine;
	}
}

// OCCUPATIONS DISPLAYED IN FAMILY FLOW

.flow-block {
	padding: 1rem;
	margin: 0;
	border-top: solid 2px darken($color: $light-color, $amount: 5);
}

.flow {
	margin: 1rem 1rem 0 1rem;
}

.occupation-meta {
	color: $grey;
	@include fluid-type(0.91rem, 0.93rem);
	font-weight: 400;
	margin: 0;
}
.occupation-toggle {
	color: $primary-color;
	@include fluid-type(1rem, 1.1rem);
	font-family: $text-font;
	cursor: pointer;
	.flow-button {
		width: auto;
		height: 1.5rem;
		margin-right: 0.5rem;
	}
}
.occupation-toggle:hover {
	color: $secondary-color;
}

@media screen and (max-width: 800px) {
	.fixed-card {
		display: none;
	}
	.like-card {
		border: solid 2px $secondary-color;
		border-radius: 12px;
		margin: 0.25rem 0;
		padding: 0.5rem;
		width: 100%;
	}
}
