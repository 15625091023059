@import "../App/App.scss";

// TOOLTIP

.tippy-box[data-animation="fade"][data-state="hidden"] {
	opacity: 0;
}
[data-tippy-root] {
	max-width: 12rem;
}
.tippy-box {
	position: relative;
	background-color: $light-color;
	color: $primary-color;
	border-radius: 5px;
	@include fluid-type(0.8rem, 0.9rem);
	line-height: 1.4;
	outline: 0;
	transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
	bottom: 2px;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
	bottom: -15px;
	left: 0;
	border-width: 15px 15px 0;
	border-top-color: initial;
	transform-origin: center top;
}

// .tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}
// .tippy-box[data-placement^=bottom]>
// .tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}
// .tippy-box[data-placement^=left]>.tippy-arrow{right:0}
// .tippy-box[data-placement^=left]>
// .tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}
// .tippy-box[data-placement^=right]>
// .tippy-arrow{left:0}
// .tippy-box[data-placement^=right]>
// .tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}

.tippy-box[data-inertia][data-state="visible"] {
	transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
	width: 1.4rem;
	height: 1.4rem;
	color: $light-color;
}
.tippy-arrow:before {
	content: "";
	position: absolute;
	border-color: transparent;
	border-style: solid;
}
.tippy-content {
	position: relative;
	padding: 10px;
	z-index: 1;
}

/*****************************/
////////   CALENDAR  /////////
/*****************************/

.calendar-container {
	margin-top: 3rem;
}

.fc {
	.fc-toolbar-title {
		font-size: 1.2rem !important;
		text-transform: capitalize;
		font-family: $text-font;
	}
	.fc-col-header-cell-cushion {
		font-family: $text-font;
	}
}

.fc-v-event {
	background-color: transparent !important;
	border: none !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
	background-color: $secondary-color !important;
	border: none !important;
}

.fc .fc-button-primary {
	background-color: lighten($color: $primary-color, $amount: 20) !important;
	border: none !important;
}

.fc .fc-button-primary:hover {
	background-color: $primary-color !important;
	border: none !important;
}

.fc .fc-button-primary:focus {
	outline: 0 !important;
}

.fc .fc-daygrid-day-number {
	color: $primary-color;
}

/*****************************/
//////   EVENTS COLOR  ////////
/*****************************/

.event-type {
	background-color: $secondary-color;
	color: $white;
	border-radius: 4px;
	padding: 0.2rem;
	margin: 0.1rem 0;
	cursor: pointer;
	@include fluid-type(0.7rem, 0.8rem);
}

.visio-type {
	background-color: $white;
	border: solid 1px $secondary-color;
	color: $secondary-color;
	font-family: $title-font;
	border-radius: 4px;
	padding: 0.2rem;
	margin: 0.1rem 0;
	cursor: pointer;
	@include fluid-type(0.7rem, 0.8rem);
}

.workshop-type {
	background-color: $primary-color;
	color: $white;
	border-radius: 4px;
	padding: 0.2rem;
	margin: 0.1rem 0;
	cursor: pointer;
	@include fluid-type(0.7rem, 0.8rem);
}
