@import "../../App/App.scss";

.notification-icon {
	width: 20px;
	height: auto;
	color: $primary-color;
	padding: 0;
}

.notification-icon-red {
	width: 20px;
	height: auto;
	color: $contrast-color;
	padding: 0;
}

.notification-ok,
.notification-no,
.notification-res {
	width: 20px;
	height: auto;
	color: $primary-color;
	cursor: pointer;
	margin: 0 0.3rem;
}

.notification-ok:hover {
	color: $secondary-color;
}

.notification-no:hover {
	color: $contrast-color;
}

.notification-res:hover {
	color: $secondary-color;
}

.notification-is-ok {
	width: 21px;
	height: auto;
	color: $secondary-color;
}

.notification-is-no {
	width: 21px;
	height: auto;
	color: $contrast-color;
}

.notification {
	padding-top: 0.75rem;
}

.subject {
	cursor: pointer;
}

// Notifications Details

.family-card {
	position: relative;
	border: solid 1px $secondary-color;
	border-radius: 7px;
	margin: 2rem 5rem 0 0;
	padding: 1rem;
	transition-duration: 0.4s;
	background-color: $light-color;
}

.family-card:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 5%;
	width: 0;
	height: 0;
	border: 18px solid transparent;
	border-top-color: $secondary-color;
	border-bottom: 0;
	border-right: 0;
	margin-left: -9px;
	margin-bottom: -18px;
}

.unread-icon {
	width: 18px;
	height: auto;
	margin-right: 0.5rem;
}

.response-card {
	position: relative;
	border: solid 1px $grey;
	border-radius: 7px;
	margin: 2rem 0 0 5rem;
	padding: 1rem;
	transition-duration: 0.4s;
}

.response-card:after {
	content: "";
	position: absolute;
	bottom: 0;
	right: 5%;
	width: 0;
	height: 0;
	border: 18px solid transparent;
	border-top-color: $grey;
	border-bottom: 0;
	border-left: 0;
	margin-left: -9px;
	margin-bottom: -18px;
}

.reponse-icon {
	width: 24px;
	height: auto;
	color: $white;
}

.thread-list {
	list-style-type: none;
	margin-bottom: 3rem;
	padding: 0;
	max-width: 88vw;
}

.mailbox {
	p {
		line-height: 0.8rem;
		@include fluid-type(0.8rem, 0.9rem);
	}
}

.thread-msg {
	@include fluid-type(0.85rem, 0.9rem);
	letter-spacing: 0.25px;
	margin: 1.5rem 0;
}

.toggle-blue {
	color: $blue;
	height: 1.75rem;
	width: auto;
	cursor: pointer;
}

.visio-box {
	height: 100%;
	max-height: 20rem;
	overflow-y: auto;
	margin-top: 2rem;
}
