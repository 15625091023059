@import "../App/App.scss";

.prod {
	width: 100%;
	height: 0.35rem;
	background-color: $contrast-color;
	color: $white;
	position: absolute;
	top: 0;
	z-index: 99;
}

.page-layout {
	position: relative;
	margin: 0;
	padding: 0;
}

/*****************************/
/////    REACT-SELECT    /////
/*****************************/

.react-select__menu {
	.react-select__option--is-focused {
		color: $primary-color;
		background-color: darken($color: $light-color, $amount: 4);
	}
	.react-select__option--is-selected {
		color: $white;
		background-color: $primary-color;
	}
}

/*****************************/
///// ONLY FOR ADMIN USER /////
/*****************************/

.admin-main-nav {
	background-color: $primary-color;
	width: 8rem;
	height: 100%;
	overflow-x: auto;
	padding: 0 0 1.5rem 0;
	margin: -1rem 0 0 0;
}

.admin-main-nav-links {
	margin: 1.6rem 0 0 0;
	cursor: pointer;
	color: $white;

	.nav-text-link {
		text-transform: uppercase;
		font-size: 0.7rem;
		padding: 0.2rem 0 0 0;
		margin: 0;
		font-family: $text-font;
		color: $white;
	}
}

.admin-main-nav-links:hover {
	.nav-icon-link {
		transform: scale(1.3);
		color: $secondary-color;
	}
}

/*****************************/
////////   MAIN NAV  /////////
/*****************************/

.nav-icon-link {
	width: 1rem;
	height: auto;
}

.nav-icon-selected {
	width: 1.5rem;
	height: auto;
	color: $secondary-color;
}

.my-main-nav {
	background-color: $white;
	width: 8rem;
	height: 100%;
	overflow-x: auto;
	padding: 0 0 2rem 0;
	margin: -0.8rem 0 0 0;
}

.main-nav-links {
	margin: 1.6rem 0 0 0;
	cursor: pointer;
	color: $primary-color;

	.nav-text-link {
		text-transform: uppercase;
		font-size: 0.7rem;
		padding: 0.1rem 0 0 0;
		margin: 0;
		font-family: $text-font;
		letter-spacing: 0;
	}
}

.main-nav-links:hover {
	.nav-text-link {
		color: $secondary-color;
	}

	.nav-icon-link {
		transform: scale(1.3);
		color: $secondary-color;
	}
}

/*****************************/
///////   SECONDARY NAV  /////
/*****************************/

.layout-with-secondary-nav {
	margin: 0 -1rem;
}

.small-secondary-nav {
	background-color: darken($color: $light-color, $amount: 4);
	height: 100%;
	padding: 1rem;
	border-radius: 0 8px 8px 0;
	max-width: 3.5rem;
	width: 100%;
	margin: 0;
}
.secondary-nav-icon {
	width: auto;
	height: 1.4rem;
	margin: 0;
	color: $primary-color;
}
.secondary-nav-icon:hover {
	color: $secondary-color;
}

.my-secondary-nav {
	background-color: darken($color: $light-color, $amount: 4);
	height: 100%;
	padding: 1rem;
	border-radius: 0 8px 8px 0;
	max-width: 14rem;
	width: 100%;
	margin: 0;
}

.light-separator {
	width: 20%;
	border-bottom: solid 1px lighten($color: $grey, $amount: 30);
	margin: 1.25rem 0;
}

.secondary-nav-link {
	margin: 0;
	padding: 0;
	line-height: 1.75rem;
}
/*****************************/
///////   HEADER WRAPPER  /////
/*****************************/

.right {
	float: right;
}

.navbar {
	position: fixed;
	top: 0;
	width: 100%;
}

header {
	background-color: $white;
	width: 100%;
	margin-bottom: 1rem;

	.nav-header {
		margin: 0 0.5rem;
	}

	.nav-header:hover {
		color: $secondary-color;
	}

	.nav-icon {
		width: 1.2rem;
		height: auto;
		margin: 0.5rem 0.3rem 0 0;
		color: $primary-color;
		cursor: pointer;
	}

	.nav-icon:hover {
		color: $secondary-color;
	}

	.nav-header-notification {
		margin: 0 0.5rem;
		position: relative;
	}

	.logo {
		width: auto;
		height: 3.1rem;
		cursor: pointer;
		margin: 0.1rem 0 0 0.2rem;
		padding: 0;
	}
	.amd-logo {
		width: auto;
		height: 3.3rem;
		cursor: pointer;
		margin: 0 0 0 0.3rem;
		padding: 0;
	}
}

/*****************************/
///////   MAIN CONTENT /////
/*****************************/

.main-content {
	padding: 0 1rem;
	width: 100%;
	margin: 0;
	height: 100%;
	min-height: 92vh;
	position: relative;
}

/*****************************/
///////    MODERATION  /////
/*****************************/

.moderation-alert {
	width: 100%;
	position: relative;
}

.moderation-card-icon-notification {
	background-color: $contrast-color;
	color: $white;
	font-size: 0.6rem;
	font-family: $text-font;
	width: 0.9rem;
	height: 0.9rem;
	border-radius: 50%;
	position: absolute;
	top: 0.9rem;
	right: 2.95rem;
}

.responsive-menu-button {
	display: none;
}

.responsive-visibility {
	display: contents;
	margin: 0;
	padding: 0;
}

/**********************************/
///////    LOADER   /////
/**********************************/

.dots {
	width: 50px;
	height: 24px;
	background: radial-gradient(circle closest-side, currentColor 90%, #0000) 0% 50%,
		radial-gradient(circle closest-side, currentColor 90%, #0000) 50% 50%,
		radial-gradient(circle closest-side, currentColor 90%, #0000) 100% 50%;
	background-size: calc(100% / 3) 12px;
	background-repeat: no-repeat;
	animation: d3 1s infinite linear;
}
@keyframes d3 {
	20% {
		background-position: 0% 0%, 50% 50%, 100% 50%;
	}
	40% {
		background-position: 0% 100%, 50% 0%, 100% 50%;
	}
	60% {
		background-position: 0% 50%, 50% 100%, 100% 0%;
	}
	80% {
		background-position: 0% 50%, 50% 50%, 100% 100%;
	}
}

/*************************/
///////    FOOTER    /////
/*************************/

.footer-container {
	position: relative;
	margin-top: 1rem;
}

.footer-content {
	position: absolute;
	bottom: 3px;
	right: 3px;
}

/**********************************/
///////    RESPONSIVE MAIN NAV  /////
/**********************************/
@media screen and (max-width: 800px) {
	.responsive-visibility {
		display: none;
	}

	.main-content {
		padding: 0;
		width: 100%;
		margin: 0 0.5rem;
	}

	.responsive-menu-button {
		display: contents;
	}

	.responsive-nav {
		position: absolute;
		z-index: 9;
		background-color: $primary-color;
		width: 8rem;
		height: auto;
		padding: 0 0 3rem 0;
		box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
		right: 0;
		top: 4.2rem;
	}

	.main-nav-links {
		margin: 1.7rem 0 0 0;
		cursor: pointer;
		color: $white;

		.nav-icon-link {
			width: 1rem;
			height: auto;
		}

		.nav-text-link {
			color: $white;
			text-transform: uppercase;
			font-size: 0.7rem;
			padding: 0.2rem 0 0 0;
			margin: 0;
			font-family: $text-font;
		}
	}

	.admin-responsive-nav {
		position: absolute;
		z-index: 9;
		background-color: $primary-color;
		width: 8rem;
		height: auto;
		padding: 0 0 3rem 0;
		box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.3);
		right: 0;
		top: 4.2rem;
	}
}

@media screen and (max-width: 640px) {
	.main-content {
		padding: 0;
		margin: 0;
	}
}
