@import "../../../App/App.scss";

.comment-container {
	display: flex;
	margin: 0.8rem 0;
}

.comment-is-after-container {
	display: flex;
	margin: 0.5rem 0 0.5rem 3.2rem;
}

.comment-user-bullet {
	width: 5%;
	min-width: 50px;
	height: 100%;
	min-height: 50px;
	margin: 0.2rem 0;
}

.user-bullet {
	background: $secondary-color;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	text-align: center;
	padding: 5px;
	display: flex;
	height: 35px;
	width: 35px;
	color: $white;
	@include fluid-type(0.95rem, 0.97rem);
	letter-spacing: 1px;
}

.family-bullet {
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	text-align: center;
	padding: 5px;
	display: flex;
	height: 35px;
	width: 35px;
	color: $white;
	@include fluid-type(0.95rem, 0.97rem);
	letter-spacing: 1px;
	&.principal {
		background: $contrast-color;
	}
	&.affiliate {
		background: $yellow;
	}
}

.comment-card {
	flex-grow: 1;
	border: solid 1px lighten($color: $grey, $amount: 30);
	border-radius: 8px;
	padding: 10px;
}

.comment-answer-btn {
	color: $grey;
	cursor: pointer;
	width: auto;
	height: 0.9rem;
	margin: 0.3rem 0.3rem 0 0;
}

.comment-meta-link {
	font-family: $title-font;
	@include fluid-type(0.75rem, 0.8rem);
	color: $secondary-color;
	letter-spacing: 0.2px;
	line-height: 1rem;
	cursor: pointer;
}
.comment-meta-link:hover {
	text-decoration: underline;
	color: $grey;
}
