@import "../../App/App.scss";

.custom-table {
	color: $primary-color;
	background-color: white;
	font-family: $text-font;
	width: 100%;
	margin-top: 0.5rem;
	border: solid 1px darken($color: $light-color, $amount: 5);
	overflow: visible;
	th {
		padding: 0.7rem 0 0 0;
		@include fluid-type(0.85rem, 0.87rem);
		line-height: 1.35rem;
		font-family: $text-font;
		font-family: $title-font;
		color: $primary-color;
		background-color: $light-color;

		span {
			padding: 0.5rem;
		}

		.filter-options {
			margin: 0.5rem;
			width: 90%;
		}
	}

	td {
		@include fluid-type(0.8rem, 0.85rem);
		padding: 0.7rem 0 0.5rem 0.5rem;
	}

	tr:hover {
		background-color: $light-color;
	}
}

.table thead th {
	border: none;
}

.table td {
	border-top: none;
	border-bottom: solid 1px #dee2e6;
}

.table-pagination {
	span {
		color: $primary-color;
		@include fluid-type(0.8rem, 0.85rem);
		line-height: 1.35rem;
		font-family: $text-font;
		padding: 0 0.5rem;
		margin: 0 0.5rem;
	}

	.table-button {
		background: $white;
		color: $primary-color;
		@include fluid-type(0.8rem, 0.85rem);
		line-height: 1.35rem;
		border-radius: 4px;
		border: solid 1px lighten($color: $grey, $amount: 40);
		text-align: center;
		text-decoration: none;
		padding: 0.15rem 0.5rem;
		margin: 0.25rem;
		transition-duration: 0.4s;
		cursor: pointer;
	}

	.table-button:hover {
		background-color: lighten($color: $grey, $amount: 30);
		color: $white;
		border: solid 1px lighten($color: $grey, $amount: 30);
	}

	.table-button:focus {
		outline: 0;
	}
}

.table-button-icon {
	width: 1rem;
	height: auto;
	cursor: pointer;
	font-family: $text-font;
	margin-right: 0.5rem;
	vertical-align: middle;
}

.search-table-bar {
	margin-top: 1rem;
}

.form-customized {
	@include fluid-type(0.85rem, 0.87rem);
	line-height: 1.35rem;
	color: $grey;
}

.table-icon {
	width: auto;
	height: 1.15rem;
	margin: 0 0.25rem;
	cursor: pointer;
	color: $primary-color;
	display: inline-block;
}

.table-icon:hover {
	color: $blue;
}
